.header {
    position: relative;
    z-index: 50;
}

hr {
    margin: 0 0;
    background-color: #ececec;
    opacity: 1;
}


.header-wrp {
    display: flex;
    align-items: stretch;


}

.logo {
    flex-basis: 35%;
    display: flex;
    padding-right: 15px;
    align-items: center;
    position: relative;
    border-right: 1px solid #ececec;

    &__img {
        margin-right: 15px;

        .ic_logo {
            fill: $blue;
            width: 50px;
            height: 50px;
        }
    }


    /*
    &__img {
        display: block;
        margin-right: 15px;

        img {
            max-width: 120px;
        }
    }*/

    &__title {
        font-size: 20px;
        font-weight: 600;
        color: $fontd;
        text-transform: uppercase;
        margin-bottom: 5px;
    }

    &__descriptor {
        font-size: 16px;
        color: $fontl;
        line-height: 1.3;
        flex-basis: 58%;
    }

}

.adress {
    flex-basis: 25%;
    display: flex;
    border-right: 1px solid #ececec;
    padding: 0 15px;
    align-items: center;

    &__icon {
        fill: $blue;
        width: 16px;
        height: 20px;
        margin-right: 20px;
    }

    &__info {
        margin-bottom: 10px;
    }

    &__title {
        font-size: 14px;
        color: $fontl;
        line-height: 1.3;
    }


}

.messengers {
    flex-basis: 25%;
    display: flex;
    border-right: 1px solid #ececec;
    padding: 0 15px;
    align-items: center;

    &__title {
        font-size: 16px;
        color: $fontd;
        line-height: 1.3;
    }

    &__icons {
        display: flex;

        .ic_mes {
            width: 15px;
            height: 15px;
            fill: $fontd;
        }

        a {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background: #ececec;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                background: $blue;

            }

            &:hover .ic_mes {
                fill: #fff;
            }
        }


    }
}


.phones {
    flex-basis: 15%;
    text-align: end;

    &__recall {
        font-weight: 600;
        color: $blue;
        text-decoration: underline;
        margin-bottom: 10px;
        display: block;
        margin-top: 15px;
        cursor: pointer;
    }

    &__phone {
        text-decoration: none;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
        display: block;
        color: $fontd;
    }

    &__email {
        font-size: 16px;
        color: $fontd;
        display: block;
        margin-bottom: 15px;
    }
}

.menu_wrp {
    display: flex;
}

.menu_wrap {
    top: -100px;
    background: $blue
}

.last-link {
    color: $blue !important;
}

@media only screen and (max-width : 1400px) {
    .logo {
        &__wrp {
            flex-basis: 50%;
        }

        &__descriptor {
            flex-basis: 50%;

        }
    }

    .phones {
        &__phone {
            font-size: 18px;
        }
    }
}


@media only screen and (max-width : 1200px) {
    .logo {

        &__descriptor {

            font-size: 13px;

        }

        &__title {
            font-size: 18px;
        }
    }

    .adress {
        &__title {
            font-size: 13px;
        }

        &__info {
            font-size: 13px;
        }


    }

    .messengers {
        &__title {
            font-size: 13px;
            margin-right: 5px;
        }
    }

    .phones {

        &__recall {
            font-size: 13px;
        }

        &__phone {
            font-size: 14px;

        }

        &__email {
            font-size: 13px;
        }
    }

}


.scrolled {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    box-shadow: 0 2px 20px #0000000a;
    background: #fff;
    transition: top 1s;
    z-index: 900;
}

@media only screen and (max-width : 990px) {
    .header-wrp {
        display: none;
    }

    .menu_wrp {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .header hr {
        display: none;
    }
}
