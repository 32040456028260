.product {
    padding: 30px 15px;
    background: #fff;
    text-align: center;
    margin-right: 10px;
    height: 475px;
    border: 1px solid #ececec;
    margin-bottom: 30px;

    &__img {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        justify-content: center;

    }

    &__category {
        color: $fontl;
        font-size: 16px;
        margin-bottom: 20px;
    }

    &__title {
        line-height: 1.3;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 30px;
        display: block;
        color: $fontd;


    }

    &__btn {
        display: inline-block;
        max-width: 267px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        background: $blue;
        padding: 17px 20px;
        border-radius: 4px;

        &:hover {
            color: #fff;
            background: darken($blue, 5%);
        }
    }


}

@media only screen and (max-width : 576px) {
    .product {
        margin-right: 0;
    }
}
