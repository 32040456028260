//прижимаем футер

.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}




.footer {
    margin-top: auto;
    padding: 40px 0;
    background: #232F3E;

    hr {
        background-color: #fff;
        opacity: .1;
        margin: 20px 0;
    }

    .messengers {
        margin: 20px 0;
        border-right: none;
        align-items: flex-start;
        padding: 0;

    }

    &__logos {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        /*  img {
            margin-bottom: 15px;
            width: 140px;
            height: 140px;
        }*/

        .ic_logo {
            fill: #4dbfe0;
            width: 50px;
            height: 50px;
            margin-right: 15px;
        }

        .logo__title {
            font-size: 24px;
            color: #fff;
        }

    }




    &__name {
        color: #fff;
        font-size: 24px;
        margin-bottom: 15px;
        font-weight: 600;
        text-transform: uppercase;

    }

    &__descriptor {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #66727F;

    }


    &__title {
        color: #fff;
        font-size: 18px;
        margin-bottom: 30px;
        font-weight: 600;
        text-transform: uppercase;
    }

    &__phones {
        margin-bottom: 30px;
    }

    &__phone {
        display: block;
        color: #fff;
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: 600;

    }

    &__ws {
        margin-bottom: 20px;

        a {
            font-size: 16px;
            color: #fff;
            opacity: .5;

            &:hover {
                opacity: 1;
            }
        }
    }

    &__contact {
        display: flex;
        margin-bottom: 20px;


        a {
            font-size: 16px;
            color: #fff;
            opacity: .5;
            margin-bottom: 15px;
            display: block;
            line-height: 1.4;

            &:hover {
                opacity: 1;
            }

        }
    }

    &__ic {
        width: 17px;
        height: 17px;
        fill: red;
        margin-right: 10px;
        margin-top: 5px;
    }

    &__menu {
        margin-bottom: 30px;

        a {
            font-size: 16px;
            color: #fff;
            opacity: .5;
            margin-bottom: 15px;
            display: block;
            line-height: 1.4;

            &:hover {
                opacity: 1;
            }

        }
    }

    &__info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__privacy {
        flex-basis: 50%;
        font-size: 12px;
        color: #fff;
        opacity: .5;
        line-height: 1.4;
    }

    &__delosait {
        display: flex;
        flex-basis: 20%;
        justify-content: flex-end;

        a {
            font-size: 12px;
            opacity: .5;
            color: #fff;

            &:hover {
                opacity: 1;
            }
        }
    }
}

.payment {
    display: flex;

    &__item {
        margin-right: 11px;
    }
}

@media only screen and (max-width : 560px) {
    .footer {
        &__privacy {
            flex-basis: 100%;
            margin-bottom: 20px;
        }

        &__delosait {
            flex-basis: 100%;
            margin-bottom: 20px;
        }

        &__title {
            margin-bottom: 20px;
            font-size: 16px;
        }

        &__menu {

            a {
                font-size: 14px;
                line-height: 1.3;
            }
        }

        &__phone {
            font-size: 16px;
        }

        &__contact {
            a {
                font-size: 14px;
                margin-bottom: 0px;
            }
        }

        &__ic {
            margin-top: 0;
        }

        &__delosait {

            justify-content: flex-start;


        }



    }

}
