.heading-banner {

    h1 {

        font-size: 34px;
        color: #fff;
        line-height: 1.3;
        margin-bottom: 20px;
        margin-top: 0;
        font-weight: 600;
    }



    .breadcrumbs {
        li {
            display: inline-block;
            font-size: 15px;
            color: #ffffffa6;

            a {
                color: #fff;
                text-decoration: none;

                &:hover {
                    color: #ffffffad;
                }
            }
        }

        li + li {
            &::before {
                color: #ffffffad;
                content: "/";
                padding: 0 5px;
            }

        }
    }
}

.btns-wrp {
    margin-top: 40px;
}


@media only screen and (max-width : 992px) {
    .heading-banner {
        padding: 40px 0;
    }

    .inner-img {
        display: none;
    }

}

@media only screen and (max-width : 576px) {
    .heading-banner {
        h1 {
            font-size: 24px;
        }
    }


}
