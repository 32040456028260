.arrows,
    {
    display: flex;
    justify-content: flex-end;
    margin-top: -100px;
    position: relative;
    z-index: 10;

    .pr-prev,
    .pr-next {
        background: #fff;
        width: 63px;
        height: 63px;
        font-size: 24px;
        border: none;
        border-radius: 4px;

        &:hover {
            background: #ececec;
        }
    }

    button {
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}


.arrows-tabs1 {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    z-index: 10;
    top: -120px;
    right: 15px;

    .pr-prev1,
    .pr-next1 {
        background: #F2F2F2;
        width: 63px;
        height: 63px;
        font-size: 24px;
        border: none;
        border-radius: 4px;

        &:hover {
            background: darken(#F2F2F2, 5%)
        }
    }

    button {
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}

.arrows-tabs2 {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    z-index: 10;
    top: -120px;
    right: 15px;

    .pr-prev2,
    .pr-next2 {
        background: #F2F2F2;
        width: 63px;
        height: 63px;
        font-size: 24px;
        border: none;
        border-radius: 4px;

        &:hover {
            background: darken(#F2F2F2, 5%)
        }
    }

    button {
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}

.arrows-tabs3 {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    z-index: 10;
    top: -120px;
    right: 15px;

    .pr-prev3,
    .pr-next3 {
        background: #F2F2F2;
        width: 63px;
        height: 63px;
        font-size: 24px;
        border: none;
        border-radius: 4px;

        &:hover {
            background: darken(#F2F2F2, 5%)
        }
    }

    button {
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}

@media only screen and (max-width : 576px) {

    .arrows-tabs2,
    .arrows-tabs3,
    .arrows-tabs1 {
        top: -70px;
    }
}

.about-arrows,
    {
    display: flex;
    justify-content: flex-end;
    margin-top: -70px;
    margin-right: 20px;
    position: relative;
    z-index: 10;

    .prev,
    .next {
        background: #fff;
        width: 63px;
        height: 63px;
        font-size: 24px;
        border: none;
        border-radius: 4px;

        &:hover {
            background: #ececec;
        }
    }

    button {
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}
