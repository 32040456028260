.sidebar-nav {
    li {
        border-bottom: 1px solid rgba(236, 236, 236, 1);
    }

    .active {
        a {
            font-weight: 600;
            color: $fontd;
        }
    }

    a {
        padding-left: 15px;
        display: block;
        font-weight: 400;
        color: $fontl;
        line-height: 64px;

        &:hover {
            color: $fontd;

        }
    }
}
