.scrolled {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    box-shadow: 0 2px 20px #0000000a;
    background: $blue;
    transition: top 1s;
    z-index: 900;
}


@media only screen and (max-width : 768px) {
    .lines {
        display: none;
    }
}


.wrapper {
    position: relative;
    background: $blue;
    border-radius: 4px;
    z-index: 900;
    width: 100%;
}

@media only screen and (max-width : 990px) {
    .wrapper {
        background: $blue;
    }
}

.header-wrap {
    position: relative;
}

.mm-menu {
    z-index: 90;
}


.mega-sub {
    margin-bottom: 30px;
}

.mega-sub i {
    margin-right: 5px;
}

.sf-menu {
    position: static;
    float: none;
    padding: 0 0px;

}

.sf-menu .sf-mega {
    background-color: #fff;


}

.sf-menu .sf-mega ul {
    display: block;
    float: none;
    position: relative;
    box-shadow: none;
}

.sf-menu li {
    white-space: normal;
    position: static;
}

.sf-menu li a {
    &:hover {
        color: #fff;
    }

}


.sf-menu > li > ul {
    width: 220px;
    left: auto;
    background: #fff;
}

.sf-menu > li > ul li .sf-with-ul::after {
    content: "";
    right: 20px;
    position: absolute;
    top: 0px;
    bottom: 0;
    margin: auto;
    background: url(../img/dropdwn.svg);
    background-repeat: no-repeat;
    width: 7px;
    height: 7px;
}


.dropdwn:after {
    content: "";
    right: 5px;
    position: absolute;
    transform: rotate(90deg);
    top: 5px;
    bottom: 0;
    margin: auto;
    background: url(../img/dropdwn.svg);
    background-repeat: no-repeat;
    width: 7px;
    height: 7px;
}

.responsive-img {
    width: 100%;
    height: 160px;
    object-fit: cover;
}

.toggle-mnu {
    display: block;
    width: 28px;
    height: 28px;
    margin-top: 14px;
}

.toggle-mnu span:after,
.toggle-mnu span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 9px;
}

.toggle-mnu span:after {
    top: 18px;
}

.toggle-mnu span {
    position: relative;
    display: block;
}

.toggle-mnu span,
.toggle-mnu span:after,
.toggle-mnu span:before {
    width: 100%;
    height: 2px;
    background-color: #fff;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 2px;
}

.toggle-mnu.on span {
    background-color: transparent;
}

.toggle-mnu.on span:before {
    -webkit-transform: rotate(45deg) translate(-1px, 0px);
    -ms-transform: rotate(45deg) translate(-1px, 0px);
    transform: rotate(45deg) translate(-1px, 0px);
}

.toggle-mnu.on span:after {
    -webkit-transform: rotate(-45deg) translate(6px, -7px);
    -ms-transform: rotate(-45deg) translate(6px, -7px);
    transform: rotate(-45deg) translate(6px, -7px);
}

.toggle-mnu {
    float: left;
    margin-top: 8px;
    position: relative;
    z-index: 2;
}

.sf-menu {
    margin-bottom: 0;
}

.sf-menu a {
    color: #333;
    background: transparent;
    font-weight: 500;
}

.sf-menu > li {
    background: transparent;
}


.sf-menu > li > a {
    padding: 20px 20px 20px 20px;
    border-bottom: 5px solid transparent;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    color: #fff;
    font-size: 18px;
    background: transparent;
    font-weight: 400;
}

.sf-menu > li > a + * {
    border-top: red solid 5px;
}

.sf-menu > li > a + * a {
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;

}

.sf-menu > li > a + * a:hover {
    color: gray;
}



.sf-menu li:hover {
    background: transparent;
    border: none;
}

.sf-menu li {
    background: transparent;
    border: none;
}

.sf-menu > li > a {
    border: none !important;
}

.sf-menu > li:hover > a {
    background-color: transparent;

}


.sf-menu > li > a {
    background: $blue;
}

.sf-menu > li > a {
    &:hover {
        background: lighten($blue, 5%);
    }

}

.sf-menu .container-fluid {
    padding-right: 15px !important;
    padding-left: 15px !important;
    margin-right: auto !important;
    margin-left: auto !important;
}

.sf-menu .row {
    margin-right: -15px !important;
    margin-left: -15px !important;
}

.sf-menu .col-lg-1,
.sf-menu .col-lg-10,
.sf-menu .col-lg-11,
.sf-menu .col-lg-12,
.sf-menu .col-lg-2,
.sf-menu .col-lg-3,
.sf-menu .col-lg-4,
.sf-menu .col-lg-5,
.sf-menu .col-lg-6,
.sf-menu .col-lg-7,
.sf-menu .col-lg-8,
.sf-menu .col-lg-9,
.sf-menu .col-md-1,
.sf-menu .col-md-10,
.sf-menu .col-md-11,
.sf-menu .col-md-12,
.sf-menu .col-md-2,
.sf-menu .col-md-3,
.sf-menu .col-md-4,
.sf-menu .col-md-5,
.sf-menu .col-md-6,
.sf-menu .col-md-7,
.sf-menu .col-md-8,
.sf-menu .col-md-9,
.sf-menu .col-sm-1,
.sf-menu .col-sm-10,
.sf-menu .col-sm-11,
.sf-menu .col-sm-12,
.sf-menu .col-sm-2,
.sf-menu .col-sm-3,
.sf-menu .col-sm-4,
.sf-menu .col-sm-5,
.sf-menu .col-sm-6,
.sf-menu .col-sm-7,
.sf-menu .col-sm-8,
.sf-menu .col-sm-9,
.sf-menu .col-xs-1,
.sf-menu .col-xs-10,
.sf-menu .col-xs-11,
.sf-menu .col-xs-12,
.sf-menu .col-xs-2,
.sf-menu .col-xs-3,
.sf-menu .col-xs-4,
.sf-menu .col-xs-5,
.sf-menu .col-xs-6,
.sf-menu .col-xs-7,
.sf-menu .col-xs-8,
.sf-menu .col-xs-9 {
    padding-right: 15px !important;
    padding-left: 15px !important;
}

.menu-new-item {
    margin-bottom: 30px;
}

.menu-new-item h3 {
    margin: 15px 0;
}

.menu-new-item a {
    padding: 0;
    font-size: 18px;
    text-decoration: underline;
    color: $blue;
}

.menu-new-item p {
    font-size: 14px;
    color: #666;
}

.sf-menu > li > a + * .menu-new-item a {
    border-bottom: none;
}

.sf-menu > li > a + * .menu-new-item a:hover {
    color: #333;
}

.mega-sub img {
    display: inline-block;
    margin-right: 10px;
    max-width: 30px;
    height: auto;
}

.small-sub img {
    display: inline-block;
    margin-right: 10px;
    max-width: 30px;
    height: auto;
}


.mega-sub a {
    display: flex;
    align-items: center;
}

.small-sub a {
    display: flex;
    align-items: center;
}


.mobile_item {
    display: none;
}

html.mm-pagedim.mm-opening #mm-blocker,
html.mm-pagedim-white.mm-opening #mm-blocker,
html.mm-pagedim-black.mm-opening #mm-blocker {
    opacity: 0.3;
    transition: opacity 0.4s ease 0.4s;



    &::after {
        content: "";
        background: url(../img/ic-close.svg) no-repeat 0 0;
        color: #fff;
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        z-index: 99999;
        top: 10px;
        left: -30px;


    }
}



@media only screen and (max-width: 990px) {
    .on {
        position: fixed;
        left: 0;
        top: 0;
        width: 20px;
        background: #00000073;
        margin-top: 30px;
        height: 20px;
        color: #fff;
        padding-left: 3px;
        display: none;

        span:after,
        span:before {
            background-color: #fff;

        }

    }



    .header_wrp {
        display: flex;
        justify-content: flex-end;
        position: fixed;
        right: 15px;

    }

    .top-menu_block,
    .search_block {
        display: none;
    }

    .dropdwn:after {
        display: none;
    }

    .mobile_item {
        display: flex !important;
    }

    .top-mnu ul {
        display: none;
    }

    /*   .header-wrap {
       padding-bottom: 10px;
   }*/
}


@media only screen and (min-width: 991px) {
    .toggle-mnu {
        display: none;
    }

    .toggle-mnu,
    #mobile-menu,
    html.mm-blocking #mm-blocker {
        display: none;
    }

    html.mm-opening .mm-slideout {
        -webkit-transform: translate(0px, 0px);
        -ms-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
    }
}

.mega-sub {
    top: 0px !important;
}


.toggle-mobile {
    margin-right: 20px;
    margin-top: 6px;
    margin-bottom: 0;
    display: none;
}

@media only screen and (max-width: 991px) {
    .fixed .toggle-mobile {
        display: inline-block;
    }
}
