.tizers_page {
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border: 1px solid #ececec;
    justify-content: flex-start;
    margin-top: 20px;

    .tizer_page {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        img {
            width: 40px;
            height: 40px;
            margin-right: 20px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .tizer_title {
            font-weight: 600;
            line-height: 1.3;
            font-size: 16px;
        }
    }

}
