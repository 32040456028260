.mobile_buttons {
    display: none;
}

@media only screen and (max-width : 990px) {

    .mobile_buttons {
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        border-left: 1px solid #ffffff26;

        a {
            background: $blue;
            width: 47px;
            height: 47px !important;
            margin-right: 2px;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 1px solid #ffffff26;

        }

        .ic_mob {
            width: 20px;
            height: 20px;
            fill: #fff;
        }
    }
}
