    .custom-checkbox {
        display: flex;
        width: 100%;
        margin: 0;
        cursor: pointer;

    }

    .checkbox-hidden {
        display: none;
    }

    .checkbox-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        max-width: 20px;
        flex: 0 0 20px;
        height: 20px;
        border-radius: 2px;

        background: $blue;
    }

    .custom-checkbox input:checked + .checkbox-wrap::after {
        transform: scale(1);
        opacity: 1;
    }

    .custom-checkbox .checkbox-wrap::after {
        content: "";
        display: block;
        width: 12px;
        height: 10px;
        background: url(../img/svg-check-ic.svg) no-repeat 0 0;
        will-change: transform;
        transform: scale(0);
        opacity: 0;
        transition: all 0.1s linear;

    }

    .checkbox-text {
        margin-left: 10px;
        color: $fontl;
        line-height: 1.4;
        margin-top: -3px;
        font-size: 12px;
        width: 100%;

        a {
            color: $blue;
            text-decoration: underline;
        }

    }

    @media only screen and (max-width : 576px) {
        .checkbox-text {

            font-size: 14px;

        }
    }
