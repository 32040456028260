.banner_block {
    position: relative;
    margin-bottom: 20px;

    img {
        width: 100%;
        display: block;
    }

}

.banner_block__info {
    position: absolute;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
}

.banner_title {
    color: #000;
    font-weight: 400;
    font-size: 17px;
    display: inline-block;
    line-height: 1.3;
    padding: 5px 10px;
    background: #FFC700;
    margin-bottom: 10px;
}

.banner_info {
    color: #fff;
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 20px;
    line-height: 1.3;
    padding: 0 10px;

}
