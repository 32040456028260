@charset "UTF-8";
.header {
  position: relative;
  z-index: 50;
}

hr {
  margin: 0 0;
  background-color: #ececec;
  opacity: 1;
}

.header-wrp {
  display: flex;
  align-items: stretch;
}

.logo {
  flex-basis: 35%;
  display: flex;
  padding-right: 15px;
  align-items: center;
  position: relative;
  border-right: 1px solid #ececec;
  /*
  &__img {
      display: block;
      margin-right: 15px;

      img {
          max-width: 120px;
      }
  }*/
}
.logo__img {
  margin-right: 15px;
}
.logo__img .ic_logo {
  fill: #0B5393;
  width: 50px;
  height: 50px;
}
.logo__title {
  font-size: 20px;
  font-weight: 600;
  color: #0B1F33;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.logo__descriptor {
  font-size: 16px;
  color: #66727F;
  line-height: 1.3;
  flex-basis: 58%;
}

.adress {
  flex-basis: 25%;
  display: flex;
  border-right: 1px solid #ececec;
  padding: 0 15px;
  align-items: center;
}
.adress__icon {
  fill: #0B5393;
  width: 16px;
  height: 20px;
  margin-right: 20px;
}
.adress__info {
  margin-bottom: 10px;
}
.adress__title {
  font-size: 14px;
  color: #66727F;
  line-height: 1.3;
}

.messengers {
  flex-basis: 25%;
  display: flex;
  border-right: 1px solid #ececec;
  padding: 0 15px;
  align-items: center;
}
.messengers__title {
  font-size: 16px;
  color: #0B1F33;
  line-height: 1.3;
}
.messengers__icons {
  display: flex;
}
.messengers__icons .ic_mes {
  width: 15px;
  height: 15px;
  fill: #0B1F33;
}
.messengers__icons a {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.messengers__icons a:last-child {
  margin-right: 0;
}
.messengers__icons a:hover {
  background: #0B5393;
}
.messengers__icons a:hover .ic_mes {
  fill: #fff;
}

.phones {
  flex-basis: 15%;
  text-align: end;
}
.phones__recall {
  font-weight: 600;
  color: #0B5393;
  text-decoration: underline;
  margin-bottom: 10px;
  display: block;
  margin-top: 15px;
  cursor: pointer;
}
.phones__phone {
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
  color: #0B1F33;
}
.phones__email {
  font-size: 16px;
  color: #0B1F33;
  display: block;
  margin-bottom: 15px;
}

.menu_wrp {
  display: flex;
}

.menu_wrap {
  top: -100px;
  background: #0B5393;
}

.last-link {
  color: #0B5393 !important;
}

@media only screen and (max-width: 1400px) {
  .logo__wrp {
    flex-basis: 50%;
  }
  .logo__descriptor {
    flex-basis: 50%;
  }

  .phones__phone {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1200px) {
  .logo__descriptor {
    font-size: 13px;
  }
  .logo__title {
    font-size: 18px;
  }

  .adress__title {
    font-size: 13px;
  }
  .adress__info {
    font-size: 13px;
  }

  .messengers__title {
    font-size: 13px;
    margin-right: 5px;
  }

  .phones__recall {
    font-size: 13px;
  }
  .phones__phone {
    font-size: 14px;
  }
  .phones__email {
    font-size: 13px;
  }
}
.scrolled {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  box-shadow: 0 2px 20px #0000000a;
  background: #fff;
  transition: top 1s;
  z-index: 900;
}

@media only screen and (max-width: 990px) {
  .header-wrp {
    display: none;
  }

  .menu_wrp {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header hr {
    display: none;
  }
}
.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer {
  margin-top: auto;
  padding: 40px 0;
  background: #232F3E;
}
.footer hr {
  background-color: #fff;
  opacity: 0.1;
  margin: 20px 0;
}
.footer .messengers {
  margin: 20px 0;
  border-right: none;
  align-items: flex-start;
  padding: 0;
}
.footer__logos {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  /*  img {
      margin-bottom: 15px;
      width: 140px;
      height: 140px;
  }*/
}
.footer__logos .ic_logo {
  fill: #4dbfe0;
  width: 50px;
  height: 50px;
  margin-right: 15px;
}
.footer__logos .logo__title {
  font-size: 24px;
  color: #fff;
}
.footer__name {
  color: #fff;
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 600;
  text-transform: uppercase;
}
.footer__descriptor {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #66727F;
}
.footer__title {
  color: #fff;
  font-size: 18px;
  margin-bottom: 30px;
  font-weight: 600;
  text-transform: uppercase;
}
.footer__phones {
  margin-bottom: 30px;
}
.footer__phone {
  display: block;
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
}
.footer__ws {
  margin-bottom: 20px;
}
.footer__ws a {
  font-size: 16px;
  color: #fff;
  opacity: 0.5;
}
.footer__ws a:hover {
  opacity: 1;
}
.footer__contact {
  display: flex;
  margin-bottom: 20px;
}
.footer__contact a {
  font-size: 16px;
  color: #fff;
  opacity: 0.5;
  margin-bottom: 15px;
  display: block;
  line-height: 1.4;
}
.footer__contact a:hover {
  opacity: 1;
}
.footer__ic {
  width: 17px;
  height: 17px;
  fill: red;
  margin-right: 10px;
  margin-top: 5px;
}
.footer__menu {
  margin-bottom: 30px;
}
.footer__menu a {
  font-size: 16px;
  color: #fff;
  opacity: 0.5;
  margin-bottom: 15px;
  display: block;
  line-height: 1.4;
}
.footer__menu a:hover {
  opacity: 1;
}
.footer__info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.footer__privacy {
  flex-basis: 50%;
  font-size: 12px;
  color: #fff;
  opacity: 0.5;
  line-height: 1.4;
}
.footer__delosait {
  display: flex;
  flex-basis: 20%;
  justify-content: flex-end;
}
.footer__delosait a {
  font-size: 12px;
  opacity: 0.5;
  color: #fff;
}
.footer__delosait a:hover {
  opacity: 1;
}

.payment {
  display: flex;
}
.payment__item {
  margin-right: 11px;
}

@media only screen and (max-width: 560px) {
  .footer__privacy {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
  .footer__delosait {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
  .footer__title {
    margin-bottom: 20px;
    font-size: 16px;
  }
  .footer__menu a {
    font-size: 14px;
    line-height: 1.3;
  }
  .footer__phone {
    font-size: 16px;
  }
  .footer__contact a {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .footer__ic {
    margin-top: 0;
  }
  .footer__delosait {
    justify-content: flex-start;
  }
}
.scrolled {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  box-shadow: 0 2px 20px #0000000a;
  background: #0B5393;
  transition: top 1s;
  z-index: 900;
}

@media only screen and (max-width: 768px) {
  .lines {
    display: none;
  }
}
.wrapper {
  position: relative;
  background: #0B5393;
  border-radius: 4px;
  z-index: 900;
  width: 100%;
}

@media only screen and (max-width: 990px) {
  .wrapper {
    background: #0B5393;
  }
}
.header-wrap {
  position: relative;
}

.mm-menu {
  z-index: 90;
}

.mega-sub {
  margin-bottom: 30px;
}

.mega-sub i {
  margin-right: 5px;
}

.sf-menu {
  position: static;
  float: none;
  padding: 0 0px;
}

.sf-menu .sf-mega {
  background-color: #fff;
}

.sf-menu .sf-mega ul {
  display: block;
  float: none;
  position: relative;
  box-shadow: none;
}

.sf-menu li {
  white-space: normal;
  position: static;
}

.sf-menu li a:hover {
  color: #fff;
}

.sf-menu > li > ul {
  width: 220px;
  left: auto;
  background: #fff;
}

.sf-menu > li > ul li .sf-with-ul::after {
  content: "";
  right: 20px;
  position: absolute;
  top: 0px;
  bottom: 0;
  margin: auto;
  background: url(../img/dropdwn.svg);
  background-repeat: no-repeat;
  width: 7px;
  height: 7px;
}

.dropdwn:after {
  content: "";
  right: 5px;
  position: absolute;
  transform: rotate(90deg);
  top: 5px;
  bottom: 0;
  margin: auto;
  background: url(../img/dropdwn.svg);
  background-repeat: no-repeat;
  width: 7px;
  height: 7px;
}

.responsive-img {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.toggle-mnu {
  display: block;
  width: 28px;
  height: 28px;
  margin-top: 14px;
}

.toggle-mnu span:after,
.toggle-mnu span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 9px;
}

.toggle-mnu span:after {
  top: 18px;
}

.toggle-mnu span {
  position: relative;
  display: block;
}

.toggle-mnu span,
.toggle-mnu span:after,
.toggle-mnu span:before {
  width: 100%;
  height: 2px;
  background-color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 2px;
}

.toggle-mnu.on span {
  background-color: transparent;
}

.toggle-mnu.on span:before {
  -webkit-transform: rotate(45deg) translate(-1px, 0px);
  -ms-transform: rotate(45deg) translate(-1px, 0px);
  transform: rotate(45deg) translate(-1px, 0px);
}

.toggle-mnu.on span:after {
  -webkit-transform: rotate(-45deg) translate(6px, -7px);
  -ms-transform: rotate(-45deg) translate(6px, -7px);
  transform: rotate(-45deg) translate(6px, -7px);
}

.toggle-mnu {
  float: left;
  margin-top: 8px;
  position: relative;
  z-index: 2;
}

.sf-menu {
  margin-bottom: 0;
}

.sf-menu a {
  color: #333;
  background: transparent;
  font-weight: 500;
}

.sf-menu > li {
  background: transparent;
}

.sf-menu > li > a {
  padding: 20px 20px 20px 20px;
  border-bottom: 5px solid transparent;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  color: #fff;
  font-size: 18px;
  background: transparent;
  font-weight: 400;
}

.sf-menu > li > a + * {
  border-top: red solid 5px;
}

.sf-menu > li > a + * a {
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.sf-menu > li > a + * a:hover {
  color: gray;
}

.sf-menu li:hover {
  background: transparent;
  border: none;
}

.sf-menu li {
  background: transparent;
  border: none;
}

.sf-menu > li > a {
  border: none !important;
}

.sf-menu > li:hover > a {
  background-color: transparent;
}

.sf-menu > li > a {
  background: #0B5393;
}

.sf-menu > li > a:hover {
  background: #0d60ab;
}

.sf-menu .container-fluid {
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.sf-menu .row {
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.sf-menu .col-lg-1,
.sf-menu .col-lg-10,
.sf-menu .col-lg-11,
.sf-menu .col-lg-12,
.sf-menu .col-lg-2,
.sf-menu .col-lg-3,
.sf-menu .col-lg-4,
.sf-menu .col-lg-5,
.sf-menu .col-lg-6,
.sf-menu .col-lg-7,
.sf-menu .col-lg-8,
.sf-menu .col-lg-9,
.sf-menu .col-md-1,
.sf-menu .col-md-10,
.sf-menu .col-md-11,
.sf-menu .col-md-12,
.sf-menu .col-md-2,
.sf-menu .col-md-3,
.sf-menu .col-md-4,
.sf-menu .col-md-5,
.sf-menu .col-md-6,
.sf-menu .col-md-7,
.sf-menu .col-md-8,
.sf-menu .col-md-9,
.sf-menu .col-sm-1,
.sf-menu .col-sm-10,
.sf-menu .col-sm-11,
.sf-menu .col-sm-12,
.sf-menu .col-sm-2,
.sf-menu .col-sm-3,
.sf-menu .col-sm-4,
.sf-menu .col-sm-5,
.sf-menu .col-sm-6,
.sf-menu .col-sm-7,
.sf-menu .col-sm-8,
.sf-menu .col-sm-9,
.sf-menu .col-xs-1,
.sf-menu .col-xs-10,
.sf-menu .col-xs-11,
.sf-menu .col-xs-12,
.sf-menu .col-xs-2,
.sf-menu .col-xs-3,
.sf-menu .col-xs-4,
.sf-menu .col-xs-5,
.sf-menu .col-xs-6,
.sf-menu .col-xs-7,
.sf-menu .col-xs-8,
.sf-menu .col-xs-9 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.menu-new-item {
  margin-bottom: 30px;
}

.menu-new-item h3 {
  margin: 15px 0;
}

.menu-new-item a {
  padding: 0;
  font-size: 18px;
  text-decoration: underline;
  color: #0B5393;
}

.menu-new-item p {
  font-size: 14px;
  color: #666;
}

.sf-menu > li > a + * .menu-new-item a {
  border-bottom: none;
}

.sf-menu > li > a + * .menu-new-item a:hover {
  color: #333;
}

.mega-sub img {
  display: inline-block;
  margin-right: 10px;
  max-width: 30px;
  height: auto;
}

.small-sub img {
  display: inline-block;
  margin-right: 10px;
  max-width: 30px;
  height: auto;
}

.mega-sub a {
  display: flex;
  align-items: center;
}

.small-sub a {
  display: flex;
  align-items: center;
}

.mobile_item {
  display: none;
}

html.mm-pagedim.mm-opening #mm-blocker,
html.mm-pagedim-white.mm-opening #mm-blocker,
html.mm-pagedim-black.mm-opening #mm-blocker {
  opacity: 0.3;
  transition: opacity 0.4s ease 0.4s;
}
html.mm-pagedim.mm-opening #mm-blocker::after,
html.mm-pagedim-white.mm-opening #mm-blocker::after,
html.mm-pagedim-black.mm-opening #mm-blocker::after {
  content: "";
  background: url(../img/ic-close.svg) no-repeat 0 0;
  color: #fff;
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 99999;
  top: 10px;
  left: -30px;
}

@media only screen and (max-width: 990px) {
  .on {
    position: fixed;
    left: 0;
    top: 0;
    width: 20px;
    background: #00000073;
    margin-top: 30px;
    height: 20px;
    color: #fff;
    padding-left: 3px;
    display: none;
  }
  .on span:after,
.on span:before {
    background-color: #fff;
  }

  .header_wrp {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    right: 15px;
  }

  .top-menu_block,
.search_block {
    display: none;
  }

  .dropdwn:after {
    display: none;
  }

  .mobile_item {
    display: flex !important;
  }

  .top-mnu ul {
    display: none;
  }

  /*   .header-wrap {
      padding-bottom: 10px;
  }*/
}
@media only screen and (min-width: 991px) {
  .toggle-mnu {
    display: none;
  }

  .toggle-mnu,
#mobile-menu,
html.mm-blocking #mm-blocker {
    display: none;
  }

  html.mm-opening .mm-slideout {
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}
.mega-sub {
  top: 0px !important;
}

.toggle-mobile {
  margin-right: 20px;
  margin-top: 6px;
  margin-bottom: 0;
  display: none;
}

@media only screen and (max-width: 991px) {
  .fixed .toggle-mobile {
    display: inline-block;
  }
}
.mobile_buttons {
  display: none;
}

@media only screen and (max-width: 990px) {
  .mobile_buttons {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    border-left: 1px solid #ffffff26;
  }
  .mobile_buttons a {
    background: #0B5393;
    width: 47px;
    height: 47px !important;
    margin-right: 2px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #ffffff26;
  }
  .mobile_buttons .ic_mob {
    width: 20px;
    height: 20px;
    fill: #fff;
  }
}
.page {
  position: relative;
}

.slider-top {
  position: relative;
  z-index: 10;
  margin-top: -182px;
}

.item {
  position: relative;
}
.item__info {
  padding: 250px 0 100px 0;
}
.item__img {
  position: relative;
  width: 100%;
  height: 100%;
}
.item__img img {
  position: absolute;
  right: 0;
  bottom: 0;
}
.item__title {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 1.3;
}
.item__subtitle {
  font-size: 24px;
  margin-bottom: 20px;
  line-height: 1.3;
}
.item__date {
  padding: 7px 15px;
  background: #fff;
  border-radius: 4px;
  font-size: 24px;
  display: inline-block;
  margin-bottom: 40px;
}
.item__btn {
  display: block;
  max-width: 267px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  background: #0B5393;
  padding: 23px 40px;
  border-radius: 4px;
}
.item__btn:hover {
  color: #fff;
  background: #09467b;
}

@media only screen and (max-width: 768px) {
  .item__info {
    padding: 210px 0 20px 0;
  }
  .item__img img {
    position: relative;
  }
}
@media only screen and (max-width: 576px) {
  .item__title {
    font-size: 24px;
  }
  .item__subtitle {
    font-size: 18px;
  }
  .item__date {
    font-size: 18px;
  }
}
.arrows {
  display: flex;
  justify-content: flex-end;
  margin-top: -100px;
  position: relative;
  z-index: 10;
}
.arrows .pr-prev,
.arrows .pr-next {
  background: #fff;
  width: 63px;
  height: 63px;
  font-size: 24px;
  border: none;
  border-radius: 4px;
}
.arrows .pr-prev:hover,
.arrows .pr-next:hover {
  background: #ececec;
}
.arrows button:not(:last-child) {
  margin-right: 10px;
}

.arrows-tabs1 {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  z-index: 10;
  top: -120px;
  right: 15px;
}
.arrows-tabs1 .pr-prev1,
.arrows-tabs1 .pr-next1 {
  background: #F2F2F2;
  width: 63px;
  height: 63px;
  font-size: 24px;
  border: none;
  border-radius: 4px;
}
.arrows-tabs1 .pr-prev1:hover,
.arrows-tabs1 .pr-next1:hover {
  background: #e5e5e5;
}
.arrows-tabs1 button:not(:last-child) {
  margin-right: 10px;
}

.arrows-tabs2 {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  z-index: 10;
  top: -120px;
  right: 15px;
}
.arrows-tabs2 .pr-prev2,
.arrows-tabs2 .pr-next2 {
  background: #F2F2F2;
  width: 63px;
  height: 63px;
  font-size: 24px;
  border: none;
  border-radius: 4px;
}
.arrows-tabs2 .pr-prev2:hover,
.arrows-tabs2 .pr-next2:hover {
  background: #e5e5e5;
}
.arrows-tabs2 button:not(:last-child) {
  margin-right: 10px;
}

.arrows-tabs3 {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  z-index: 10;
  top: -120px;
  right: 15px;
}
.arrows-tabs3 .pr-prev3,
.arrows-tabs3 .pr-next3 {
  background: #F2F2F2;
  width: 63px;
  height: 63px;
  font-size: 24px;
  border: none;
  border-radius: 4px;
}
.arrows-tabs3 .pr-prev3:hover,
.arrows-tabs3 .pr-next3:hover {
  background: #e5e5e5;
}
.arrows-tabs3 button:not(:last-child) {
  margin-right: 10px;
}

@media only screen and (max-width: 576px) {
  .arrows-tabs2,
.arrows-tabs3,
.arrows-tabs1 {
    top: -70px;
  }
}
.about-arrows {
  display: flex;
  justify-content: flex-end;
  margin-top: -70px;
  margin-right: 20px;
  position: relative;
  z-index: 10;
}
.about-arrows .prev,
.about-arrows .next {
  background: #fff;
  width: 63px;
  height: 63px;
  font-size: 24px;
  border: none;
  border-radius: 4px;
}
.about-arrows .prev:hover,
.about-arrows .next:hover {
  background: #ececec;
}
.about-arrows button:not(:last-child) {
  margin-right: 10px;
}

.categories {
  margin-top: 45px;
}

.category {
  height: 246px;
  margin: 0 5px;
  position: relative;
  display: block;
  opacity: 0.9;
}
.category:hover {
  opacity: 1;
}
.category__img {
  width: 100%;
  position: relative;
  z-index: 10;
}
.category__img img {
  height: 246px;
  object-fit: cover;
  width: 100%;
}
.category__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0.31%, rgba(0, 0, 0, 0) 74.39%);
  z-index: 20;
  bottom: 0;
  left: 0;
}
.category__info {
  position: absolute;
  left: 20px;
  bottom: 15px;
  z-index: 30;
}
.category__title {
  color: #fff;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 10px;
  font-weight: 600;
}
.category__description {
  line-height: 1.3;
  color: #fff;
}

.product-tabs {
  background: #F6F7FA;
  padding: 40px 0;
}

.pos-relative {
  position: relative;
}

.product-navs {
  padding-top: 80px;
}

.block-title {
  color: #0B1F33;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 40px;
}

@media only screen and (max-width: 576px) {
  .block-title {
    font-size: 22px;
  }

  .product-navs {
    padding-top: 40px;
  }

  .product-tabs {
    padding: 80px 0;
  }
}
.banner_block {
  position: relative;
  margin-bottom: 20px;
}
.banner_block img {
  width: 100%;
  display: block;
}

.banner_block__info {
  position: absolute;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
}

.banner_title {
  color: #000;
  font-weight: 400;
  font-size: 17px;
  display: inline-block;
  line-height: 1.3;
  padding: 5px 10px;
  background: #FFC700;
  margin-bottom: 10px;
}

.banner_info {
  color: #fff;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 20px;
  line-height: 1.3;
  padding: 0 10px;
}

.product {
  padding: 30px 15px;
  background: #fff;
  text-align: center;
  margin-right: 10px;
  height: 475px;
  border: 1px solid #ececec;
  margin-bottom: 30px;
}
.product__img {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  justify-content: center;
}
.product__category {
  color: #66727F;
  font-size: 16px;
  margin-bottom: 20px;
}
.product__title {
  line-height: 1.3;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 30px;
  display: block;
  color: #0B1F33;
}
.product__btn {
  display: inline-block;
  max-width: 267px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  background: #0B5393;
  padding: 17px 20px;
  border-radius: 4px;
}
.product__btn:hover {
  color: #fff;
  background: #09467b;
}

@media only screen and (max-width: 576px) {
  .product {
    margin-right: 0;
  }
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #0B1F33;
  font-weight: 500;
  background-color: #fff;
  border-color: transparent transparent transparent;
  font-size: 18px;
}

.nav-tabs .nav-link.active {
  border-bottom: 2px solid #0B5393;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent transparent transparent;
  isolation: isolate;
  border-bottom: 2px solid #0B5393;
}

.nav-tabs .nav-link {
  color: #66727F;
  font-weight: 500;
  font-size: 18px;
}

.nav-tabs {
  border-bottom: none;
}

/* bootstrap hack: fix content width inside hidden tabs */
.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: block;
  /* undo display:none          */
  height: 0;
  /* height:0 is also invisible */
}

.tab-content > .active,
.pill-content > .active {
  height: auto;
  /* let the content decide it  */
}

/* bootstrap hack end */
@media only screen and (max-width: 576px) {
  .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    font-size: 16px;
  }

  .nav-tabs .nav-link {
    font-size: 16px;
  }
}
.about {
  padding: 60px 0;
}

.about-text {
  margin-bottom: 15px;
}
.about-text p {
  font-size: 18px;
  color: #66727F;
  line-height: 1.4;
  padding: 5px 0;
}
.about-text .p-bold {
  color: #0B1F33;
  font-weight: 600;
}

.about_slider--img {
  width: 100%;
}
.about_slider--img img {
  display: block;
  height: 400px;
  object-fit: cover;
}

@media only screen and (max-width: 576px) {
  .about {
    padding: 30px 0;
  }
  .about p {
    font-size: 16px;
  }
}
.tizers {
  padding: 40px 0;
}

.tizer {
  margin-bottom: 20px;
}
.tizer__img {
  margin-bottom: 20px;
}
.tizer__img img {
  height: 70px;
}
.tizer__title {
  color: fontd;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 1.4;
  font-size: 18px;
}
.tizer__txt {
  color: #66727F;
  line-height: 1.4;
  font-size: 18px;
}

@media only screen and (max-width: 576px) {
  .tizer {
    display: flex;
  }
  .tizer__img {
    margin-right: 15px;
    flex-basis: 20%;
  }
  .tizer__img img {
    height: 50px;
    width: 50px;
  }
  .tizer__info {
    flex-basis: 80%;
  }
  .tizer__title {
    font-size: 16px;
  }
  .tizer__txt {
    font-size: 16px;
  }
}
.heading {
  background: #fff;
  padding: 30px 0px 30px 0;
}
.heading h1 {
  font-size: 32px;
  color: #0B1F33;
  line-height: 1.3;
  margin-bottom: 20px;
  margin-top: 0;
  font-weight: 600;
}

.breadcrumbs li {
  display: inline-block;
  font-size: 15px;
  color: #66727F;
}
.breadcrumbs li a {
  color: #66727F;
  text-decoration: none;
}
.breadcrumbs li a:hover {
  color: #66727F;
}
.breadcrumbs li + li::before {
  color: #66727F;
  content: "—";
  padding: 0 5px;
}

.heading-banner h1 {
  font-size: 34px;
  color: #fff;
  line-height: 1.3;
  margin-bottom: 20px;
  margin-top: 0;
  font-weight: 600;
}
.heading-banner .breadcrumbs li {
  display: inline-block;
  font-size: 15px;
  color: #ffffffa6;
}
.heading-banner .breadcrumbs li a {
  color: #fff;
  text-decoration: none;
}
.heading-banner .breadcrumbs li a:hover {
  color: #ffffffad;
}
.heading-banner .breadcrumbs li + li::before {
  color: #ffffffad;
  content: "/";
  padding: 0 5px;
}

.btns-wrp {
  margin-top: 40px;
}

@media only screen and (max-width: 992px) {
  .heading-banner {
    padding: 40px 0;
  }

  .inner-img {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .heading-banner h1 {
    font-size: 24px;
  }
}
.btn-white {
  background: #fff;
  color: #1d7ce6;
  padding: 15px 25px;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.btn-blue {
  background: #1d7ce6;
  color: #fff;
  padding: 15px 25px;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
}
.btn-blue:hover {
  background: #5a7095;
}

.sidebar-nav li {
  border-bottom: 1px solid #ececec;
}
.sidebar-nav .active a {
  font-weight: 600;
  color: #0B1F33;
}
.sidebar-nav a {
  padding-left: 15px;
  display: block;
  font-weight: 400;
  color: #66727F;
  line-height: 64px;
}
.sidebar-nav a:hover {
  color: #0B1F33;
}

.tizers_page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border: 1px solid #ececec;
  justify-content: flex-start;
  margin-top: 20px;
}
.tizers_page .tizer_page {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.tizers_page .tizer_page img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
.tizers_page .tizer_page:last-child {
  margin-bottom: 0;
}
.tizers_page .tizer_page .tizer_title {
  font-weight: 600;
  line-height: 1.3;
  font-size: 16px;
}

.content {
  background: #fff;
  box-shadow: 0 0 20px #ececec;
  padding: 30px;
  line-height: 1.4;
}
.content .main-text {
  background: #ececec;
  padding: 30px 15px 30px 30px;
  border-left: 3px solid #0B5393;
  margin: 20px 0;
}
.content h2 {
  color: #0B1F33;
  font-size: 32px;
  margin: 10px 0;
  font-weight: 700;
}
.content h3 {
  color: #0B1F33;
  font-size: 28px;
  margin: 10px 0;
  font-weight: 600;
}
.content p {
  color: #66727F;
  padding: 7px 0;
}
.content .content-ul {
  list-style-type: disc;
  padding-left: 20px;
  color: #66727F;
}
.content .content-ul li {
  padding: 7px 0;
}

@media only screen and (max-width: 576px) {
  .content {
    padding: 0;
    box-shadow: none;
  }
  .content h2 {
    font-size: 24px;
  }
  .content h3 {
    font-size: 22px;
  }
}
.category-page {
  background: #fff;
  margin: 20px 0;
  position: relative;
  transition: 0.3s;
  display: block;
  width: 100%;
}
.category-page__img {
  width: 100%;
  display: block;
  height: 250px;
  position: relative;
  z-index: 60;
  margin-bottom: 20px;
}
.category-page__img img {
  height: 100%;
  object-fit: cover;
}
.category-page__title {
  font-weight: 600;
  color: #0B1F33;
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 20px;
  position: relative;
  z-index: 60;
}
.category-page__descr {
  position: absolute;
  opacity: 0;
  padding: 15px;
  line-height: 1.4;
  color: #66727F;
  box-shadow: 0 0 20px #00000012;
  bottom: 0%;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 90;
  max-height: 200px;
  transition: 0.3s;
}
.category-page:hover .category-page__descr {
  opacity: 1;
}

/*Скролл*/
.simplebar-track.simplebar-vertical {
  background-color: #d9d9d9;
  width: 5px;
}

.simplebar-content-wrapper {
  padding-right: 15px;
}

.simplebar-scrollbar::before {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  background-color: #2871c5;
  border-radius: 0;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
  top: 0;
  bottom: 0;
}

.simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}

@media only screen and (max-width: 576px) {
  .order1 {
    order: 2;
  }

  .order2 {
    order: 1;
  }
}
.slider-wrapper {
  display: flex;
  overflow: hidden;
  max-height: 700px;
}
.slider-wrapper .slider-thumb li {
  padding: 10px;
  border: 1px solid #ececec;
  margin-bottom: 5px;
  margin-right: 0px;
  height: 113px;
  width: 113px;
  position: relative;
}
.slider-wrapper .slider-thumb li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slider-wrapper .slider-thumb .slick-prev3 {
  left: unset;
  top: 0.5em;
}
.slider-wrapper .slider-thumb .slick-nextt3 {
  top: unset;
  right: unset;
  bottom: -0.5em;
}
.slider-wrapper .slider-thumb .slick-prev3,
.slider-wrapper .slider-thumb .slick-nextt3 {
  z-index: 1;
  height: 1em;
  width: 100%;
  background-color: #000;
  opacity: 0.3;
  transition: opacity 300ms ease;
  font-size: 1.5em;
  color: #fff;
  border: none;
}
.slider-wrapper .slider-thumb .slick-prev3::before,
.slider-wrapper .slider-thumb .slick-nextt3::before {
  content: "";
}
.slider-wrapper .slider-thumb .slick-prev3::hover,
.slider-wrapper .slider-thumb .slick-nextt3::hover {
  opacity: 0.5;
}
.slider-wrapper .slider-preview li {
  padding: 10px;
  width: 514px;
  height: 518px;
  border: 1px solid #ececec;
  margin-left: 5px;
}
.slider-wrapper .slider-preview li a {
  outline: none;
}
.slider-wrapper .slider-preview li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*
Медиа запросы для карточки товара*/
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .slider-wrapper {
    display: flex;
    overflow: hidden;
    max-height: 750px;
  }
  .slider-wrapper .slider-thumb li {
    padding: 10px;
    border: 1px solid #ececec;
    margin-bottom: 5px;
    margin-right: 0px;
    width: 231px !important;
    height: 220px !important;
  }
  .slider-wrapper .slider-thumb li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .slider-wrapper .slider-thumb .slick-prev3 {
    left: unset;
    top: 0.5em;
  }
  .slider-wrapper .slider-thumb .slick-nextt3 {
    top: unset;
    right: unset;
    bottom: -0.5em;
  }
  .slider-wrapper .slider-thumb .slick-prev3,
.slider-wrapper .slider-thumb .slick-nextt3 {
    z-index: 1;
    height: 1em;
    width: 100%;
    background-color: #000;
    opacity: 0.3;
    transition: opacity 300ms ease;
    font-size: 1.5em;
    color: #fff;
    border: none;
  }
  .slider-wrapper .slider-thumb .slick-prev3::before,
.slider-wrapper .slider-thumb .slick-nextt3::before {
    content: "";
  }
  .slider-wrapper .slider-thumb .slick-prev3::hover,
.slider-wrapper .slider-thumb .slick-nextt3::hover {
    opacity: 0.5;
  }
  .slider-wrapper .slider-preview li {
    padding: 10px;
    width: 700px;
    height: 720px;
    border: 1px solid #ececec;
    margin-left: 5px;
  }
  .slider-wrapper .slider-preview li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media only screen and (min-width: 768px) and (max-width: 979px) {
  .slider-wrapper {
    display: flex;
    overflow: hidden;
    max-height: 720px;
  }
  .slider-wrapper .slider-thumb li {
    padding: 10px;
    border: 1px solid #ececec;
    margin-bottom: 5px;
    margin-right: 0px;
    width: 231px !important;
    height: 220px !important;
  }
  .slider-wrapper .slider-thumb li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .slider-wrapper .slider-thumb .slick-prev3 {
    left: unset;
    top: 0.5em;
  }
  .slider-wrapper .slider-thumb .slick-nextt3 {
    top: unset;
    right: unset;
    bottom: -0.5em;
  }
  .slider-wrapper .slider-thumb .slick-prev3,
.slider-wrapper .slider-thumb .slick-nextt3 {
    z-index: 1;
    height: 1em;
    width: 100%;
    background-color: #000;
    opacity: 0.3;
    transition: opacity 300ms ease;
    font-size: 1.5em;
    color: #fff;
    border: none;
  }
  .slider-wrapper .slider-thumb .slick-prev3::before,
.slider-wrapper .slider-thumb .slick-nextt3::before {
    content: "";
  }
  .slider-wrapper .slider-thumb .slick-prev3::hover,
.slider-wrapper .slider-thumb .slick-nextt3::hover {
    opacity: 0.5;
  }
  .slider-wrapper .slider-preview li {
    padding: 10px;
    width: 700px;
    height: 720px;
    border: 1px solid #ececec;
    margin-left: 5px;
  }
  .slider-wrapper .slider-preview li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media only screen and (max-width: 767px) {
  * {
    min-height: 0;
    min-width: 0;
  }

  .slider-wrapper {
    display: flex;
    overflow: hidden;
    max-height: 800px;
    flex-direction: column-reverse;
  }
  .slider-wrapper .slider-thumb {
    max-width: 100%;
  }
  .slider-wrapper .slider-thumb li {
    padding: 3px;
    border: 1px solid #ececec;
    margin-bottom: 5px;
    margin-right: 5px;
    max-height: 100px;
  }
  .slider-wrapper .slider-thumb li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .slider-wrapper .slider-thumb .slick-prev3 {
    top: 0.5em;
    left: 0;
  }
  .slider-wrapper .slider-thumb .slick-prev3 .fa::before {
    content: "\f104";
  }
  .slider-wrapper .slider-thumb .slick-nextt3 {
    top: 0.5em;
    right: 0;
  }
  .slider-wrapper .slider-thumb .slick-nextt3 .fa::before {
    content: "\f105";
  }
  .slider-wrapper .slider-thumb .slick-prev3,
.slider-wrapper .slider-thumb .slick-nextt3 {
    top: 0;
    width: 1em;
    height: 100px;
    border: none;
    position: absolute;
  }
  .slider-wrapper .slider-preview li {
    padding: 0px;
    border: 1px solid #ececec;
    margin-left: 0px;
  }
  .slider-wrapper .slider-preview li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media only screen and (max-width: 540px) {
  * {
    min-height: 0;
    min-width: 0;
  }

  .slider-wrapper {
    display: flex;
    overflow: hidden;
    max-height: 800px;
    flex-direction: column-reverse;
  }
  .slider-wrapper .slider-thumb {
    max-width: 100%;
    padding: 0 23px 0 27px;
    text-align: center;
  }
  .slider-wrapper .slider-thumb li {
    padding: 3px;
    border: 1px solid #ececec;
    margin-bottom: 5px;
    margin-right: 5px;
    max-height: 100px;
  }
  .slider-wrapper .slider-thumb li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .slider-wrapper .slider-thumb .slick-prev3 {
    top: 0.5em;
    left: 0;
  }
  .slider-wrapper .slider-thumb .slick-prev3 .fa::before {
    content: "\f104";
  }
  .slider-wrapper .slider-thumb .slick-nextt3 {
    top: 0.5em;
    right: 0;
  }
  .slider-wrapper .slider-thumb .slick-nextt3 .fa::before {
    content: "\f105";
  }
  .slider-wrapper .slider-thumb .slick-prev3,
.slider-wrapper .slider-thumb .slick-nextt3 {
    top: 0;
    width: 1em;
    height: 100px;
    border: none;
    position: absolute;
  }
  .slider-wrapper .slider-preview li {
    padding: 0px;
    border: 1px solid #ececec;
    margin-left: 0px;
    height: 350px;
  }
  .slider-wrapper .slider-preview li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.product-card {
  background: #fff;
}
.product-card hr {
  background-color: #ececec;
  opacity: 1;
}

.infocard {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.infocard_sticker {
  background: #0B5393;
  color: #fff;
  padding: 7px 15px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infocard_marker {
  font-size: 14px;
  display: flex;
  align-items: center;
}
.infocard_marker span {
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.infocard_marker__green {
  color: green;
}
.infocard_marker__green span {
  background: green;
}

.ic-card {
  margin-right: 0;
}

.card-title h1 {
  font-size: 24px;
  font-weight: 700;
  margin: 20px 0;
}

.product_prices {
  display: flex;
  margin-bottom: 20px;
}

.product_prices-new {
  font-size: 23px;
  margin-right: 15px;
  font-weight: 600;
}

.product_prices-old {
  color: darkgray;
  text-decoration: line-through;
  margin-right: 15px;
}

.product_btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 60px;
}

.questions_block {
  position: relative;
  padding: 10px;
  border: 1px solid #ececec;
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.questions_block a:hover {
  opacity: 0.7;
}

.questions_block--text {
  padding: 7px;
  background: #fff;
  font-size: 13px;
  color: darkgray;
  line-height: 1.4;
}

.q_link {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(90deg, #ffc328 18%, #ff8d00 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ic_quest {
  width: 17px;
  height: 17px;
  fill: #fff;
}

.tizers-card {
  display: flex;
  justify-content: space-between;
}
.tizers-card .tizer {
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  flex-basis: auto;
}

.tizer_img {
  margin-bottom: 10px;
}

.tizer_img img {
  max-width: 40px;
  height: auto;
}

.tizer_txt {
  line-height: 1.3;
  font-size: 14px;
}

.nav-card {
  margin-top: 30px;
}

.tables {
  display: flex;
}
.tables table {
  margin: 20px;
}
.tables table .end {
  text-align: end;
}

article,
.description_card,
.card-data__row {
  padding: 20px 0;
}
article table,
.description_card table,
.card-data__row table {
  margin: 20px;
}
article p,
.description_card p,
.card-data__row p {
  line-height: 1.5;
  margin-bottom: 7px;
}
article ul li,
.description_card ul li,
.card-data__row ul li {
  line-height: 1.5;
  margin: 7px;
}
article ul span,
.description_card ul span,
.card-data__row ul span {
  width: 7px;
  height: 7px;
  display: inline-block;
  background: #0B5393;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 3px;
}
article .main_text,
.description_card .main_text,
.card-data__row .main_text {
  padding: 15px 15px 15px 40px;
  border-left: 3px solid #0B5393;
  background: #ececec;
  margin: 15px 0;
  display: block;
}

.nav-tabs {
  border-bottom: 1px solid #ececec;
}

.ic_product {
  width: 50px;
  height: 50px;
  fill: #0B5393;
}

nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #f7f7f7;
  border-color: #fff #fff #fff;
  border-bottom: 3px solid #0B5393;
  font-weight: 600;
  padding: 15px 20px;
  cursor: pointer;
}

nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  font-size: 17px;
  cursor: pointer;
  padding: 15px 20px;
  color: gray;
}
nav-tabs .nav-item.show .nav-link:hover,
.nav-tabs .nav-link:hover {
  border-color: #fff #fff #0B5393 #fff;
}

nav-tabs .nav-item.show .nav-link:focus,
.nav-tabs .nav-link:focus {
  border-bottom: 3px solid #244d72;
}

.btn-product {
  background: #0B5393;
  color: #fff;
  font-size: 24px;
  padding: 15px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}
.btn-product:hover {
  background: #09467b;
}

.description_card ul {
  margin: 20px 0;
}

@media only screen and (max-width: 1200px) {
  .infocard {
    margin-top: 30px;
  }

  .product_prices {
    flex-direction: column;
  }

  .tables {
    flex-wrap: wrap;
  }

  .tizers-card {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .tizers-card {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 470px) {
  .questions_block {
    margin-top: 30px;
    margin-left: 0;
  }
}
.action {
  position: relative;
  margin-bottom: 30px;
  height: 400px;
}
.action a {
  width: 100%;
  height: 100%;
  display: block;
}
.action img {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
}

.action__info-top {
  position: absolute;
  top: 20px;
  left: 20px;
  background: #ffd600;
  color: #0B1F33;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 4px;
}

.action__info-bottom {
  position: absolute;
  bottom: 0;
  left: 0px;
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 10px;
  width: 100%;
}

.action__time {
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  opacity: 0.8;
  margin-bottom: 15px;
}
.action__time span {
  margin-left: 7px;
}

.ic_action {
  width: 16px;
  height: 16px;
  fill: #fff;
}

.action__title {
  color: #fff;
  font-weight: 600;
  font-size: 17px;
  display: inline-block;
  line-height: 1.3;
  margin-bottom: 30px;
}

.action__overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: all 0.3s;
}

.action:hover .action__overlay {
  opacity: 0.5;
}

.action_pic {
  margin-bottom: 15px;
}

.action-info {
  display: flex;
  align-items: center;
}
.action-info__yellow {
  display: inline-block;
  background: #ffd600;
  color: #0B1F33;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 15px;
}
.action-info__white {
  border: 1px solid #ececec;
  color: #0B1F33;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
}
.action-info__white span {
  margin-left: 10px;
}
.action-info .ic_red {
  fill: red;
  width: 16px;
  height: 16px;
}

.request {
  border: 1px solid #e5e5e5;
  background: #fafafa;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
.request__wrp {
  flex-basis: 80%;
  display: flex;
}
.request__wrp img {
  margin-right: 15px;
}
.request__text {
  color: #0B1F33;
  line-height: 1.4;
  font-size: 18px;
}
.request__btn {
  flex-basis: 20%;
  background: #0B5393;
  color: #fff;
  font-weight: 600;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}
.request__btn:hover {
  background: #1d7ce6;
}

@media only screen and (max-width: 1200px) {
  .request {
    flex-wrap: wrap;
  }
  .request__wrp {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
  .request__btn {
    flex-basis: 30%;
  }
}
@media only screen and (max-width: 712px) {
  .request__wrp img {
    max-width: 40px;
  }
  .request__btn {
    flex-basis: 100%;
  }
}
.contacts {
  border: 1px solid #ececec;
  padding: 20px;
}
.contacts__top {
  width: 100%;
  height: 300px;
  position: relative;
  margin-bottom: 20px;
}
.contacts__top img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contacts__info {
  position: absolute;
  bottom: 0;
  left: 0px;
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 10px;
  width: 100%;
}
.contacts__subtitle {
  font-size: 14px;
  color: #fff;
  margin-bottom: 20px;
}
.contacts__title {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
}
.contacts__group {
  margin: 20px 0;
}
.contacts__label {
  font-size: 16px;
  color: #66727F;
  margin-bottom: 10px;
}
.contacts__input {
  margin-bottom: 10px;
}
.contacts__input a {
  color: #0B1F33;
  font-size: 18px;
  text-decoration: none;
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
}
.contacts__btn {
  width: 100%;
  background: #fff;
  border: 1px solid #0B5393;
  color: #0B5393;
  font-weight: 600;
  padding: 15px 5px;
  cursor: pointer;
  transition: all 0.3s;
  display: block;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.contacts__btn:hover {
  border: 1px solid #0B5393;
  color: #fff;
  background: #0B5393;
}

.content-contacts {
  margin: 20px 0;
}

body.modal-open {
  padding-right: 0px !important;
}

body {
  overflow-y: scroll !important;
}

.compensate-for-scrollbar {
  padding-right: 0px !important;
}

textarea:focus {
  border: none;
  outline: none !important;
}

.modal-header {
  justify-content: center;
  position: relative;
}

.graypart {
  background-color: #f6f9fc;
}

.mc-wrp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-content {
  position: relative;
}

.btn-close {
  position: absolute;
  right: 15px;
  top: 15px;
}

#watchme {
  border: none;
  background: transparent;
  box-shadow: none;
}
#watchme .btn-close {
  right: -30px;
}
#watchme .modal-content {
  border: none;
  background: transparent;
  box-shadow: none;
}

.modal-title {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.2;
  color: #0B1F33;
}

.modal-contacts {
  padding: 20px 40px;
}
.modal-contacts__heading {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 20px;
  color: #66727F;
}
.modal-contacts__contacts a {
  display: block;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
  color: #66727F;
  margin-bottom: 5px;
}
.modal-contacts__bottom {
  margin-top: 40px;
  font-size: 14px;
  display: inline-flex;
  color: #0B1F33;
  align-items: center;
  padding: 10px 17px;
  border: 1px solid #ececec;
  break-after: 4px;
}
.modal-contacts__bottom span {
  margin-left: 10px;
}
.modal-contacts__bottom .ic_modal {
  width: 20px;
  height: 20px;
  fill: #0B5393;
}

.files {
  font-size: 10px;
}

.form-group_modal {
  padding: 40px 40px;
}
.form-group_modal label {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}

.form_input__modal {
  padding: 13px 0px;
  border: none;
  border-bottom: 1px solid lightgray;
  margin-bottom: 20px;
  border-radius: 0px;
  width: 100%;
  background: transparent;
}
.form_input__modal:focus {
  border-bottom: 2px solid #0B5393;
}

.form_group__bottom-modal {
  padding: 0px 40px;
  flex-direction: column;
  margin-bottom: 20px;
}

.form_btn__modal {
  padding: 17px 40px;
  background: #0B5393;
  color: #fff;
  border: 1px solid #0B5393;
  transition: all 0.3s;
  display: block;
  margin-bottom: 30px;
  border-radius: 4px;
}
.form_btn__modal:hover {
  background: #fff;
  color: #244d72;
  border: 1px solid #0B5393;
}

.politika_modal {
  font-size: 12px;
  line-height: 1.4;
  margin-bottom: 20px;
}

.close-menu__modal {
  position: absolute;
  right: 15px;
  top: 30px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  z-index: 900;
}

.close-menu__modal span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #66727F;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.close-menu__modal span:nth-child(1) {
  top: 12px;
  width: 0%;
  left: 50%;
}

.close-menu__modal span:nth-child(2) {
  transform: rotate(45deg);
}

.close-menu__modal span:nth-child(3) {
  transform: rotate(-45deg);
}

.close-menu__modal span:nth-child(4) {
  top: 12px;
  width: 0%;
  left: 50%;
}

.reg_tabs {
  margin: 0 40px;
}

.modal-header_reg {
  border-bottom: 1px solid transparent;
  flex-direction: column;
  padding: 30px 0;
}

.modal-header {
  padding: 40px 20px 20px 40px;
  display: flex;
  flex-direction: column;
}
.modal-header h5 {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.2;
}
.modal-header h5 span {
  font-weight: 300;
  font-size: 18px;
}
.modal-header a {
  display: block;
  font-size: 22px;
  text-decoration: none;
  font-weight: 700;
}

.modal-body {
  position: relative;
  z-index: 700;
  padding: 0;
}

#success,
#success2,
#success3,
#success4 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: none;
  text-align: center;
  padding: 40px;
  display: none;
  z-index: 800;
}

.text_success {
  font-size: 34px;
  line-height: 1.5;
}

.radio_title {
  font-size: 12px;
  font-weight: 600;
}

.radio_mtitle {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
}

.radio_block {
  display: flex;
  font-size: 12px;
  align-items: center;
  margin-bottom: 7px;
}
.radio_block input,
.radio_block .radio_title,
.radio_block .radio_old-price {
  margin-right: 10px;
}

.radio_old-price {
  text-decoration: line-through;
  opacity: 0.5;
}

.radio_new-price {
  font-family: "Arial";
  font-weight: 700;
}

.form_group__bottom-modal p {
  font-size: 11px;
  margin-left: 10px;
}

.modal-content .custom_chek {
  display: flex;
  align-items: center;
  padding: 15px 0px;
}
.modal-content .custom_chek p {
  font-size: 11px;
  margin-left: 10px;
  line-height: 1.3;
}

@media only screen and (max-width: 568px) {
  .modal-title {
    font-size: 22px;
    margin-top: 20px;
  }

  .form-group_modal,
.form_group__bottom-modal {
    padding: 20px 20px;
  }

  .modal-header {
    padding-top: 60px;
  }

  .modal_heading {
    padding: 0px 0px;
    margin-bottom: 20px;
  }

  .text_success {
    font-size: 24px;
    line-height: 1.5;
  }
}
.modal-content {
  height: auto !important;
  overflow: hidden !important;
}

.span-hidden {
  display: none;
}

.custom-checkbox {
  display: flex;
  width: 100%;
  margin: 0;
  cursor: pointer;
}

.checkbox-hidden {
  display: none;
}

.checkbox-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  max-width: 20px;
  flex: 0 0 20px;
  height: 20px;
  border-radius: 2px;
  background: #0B5393;
}

.custom-checkbox input:checked + .checkbox-wrap::after {
  transform: scale(1);
  opacity: 1;
}

.custom-checkbox .checkbox-wrap::after {
  content: "";
  display: block;
  width: 12px;
  height: 10px;
  background: url(../img/svg-check-ic.svg) no-repeat 0 0;
  will-change: transform;
  transform: scale(0);
  opacity: 0;
  transition: all 0.1s linear;
}

.checkbox-text {
  margin-left: 10px;
  color: #66727F;
  line-height: 1.4;
  margin-top: -3px;
  font-size: 12px;
  width: 100%;
}
.checkbox-text a {
  color: #0B5393;
  text-decoration: underline;
}

@media only screen and (max-width: 576px) {
  .checkbox-text {
    font-size: 14px;
  }
}