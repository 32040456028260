.action {
    position: relative;
    margin-bottom: 30px;
    height: 400px;

    a {
        width: 100%;
        height: 100%;
        display: block;
    }

    img {
        width: 100%;
        display: block;
        height: 100%;
        object-fit: cover;

    }

}

.action__info-top {
    position: absolute;
    top: 20px;
    left: 20px;
    background: $yellow;
    color: $fontd;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 4px;
}

.action__info-bottom {
    position: absolute;
    bottom: 0;
    left: 0px;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 10px;
    width: 100%;

}

.action__time {
    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    opacity: .8;
    margin-bottom: 15px;

    span {
        margin-left: 7px;
    }
}

.ic_action {
    width: 16px;
    height: 16px;
    fill: #fff;
}

.action__title {
    color: #fff;
    font-weight: 600;
    font-size: 17px;
    display: inline-block;
    line-height: 1.3;
    margin-bottom: 30px;
}

.action__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    transition: all .3s;
}

.action {
    &:hover .action__overlay {
        opacity: .5;
    }
}
