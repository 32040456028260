.page {
    position: relative;

}

.slider-top {
    position: relative;
    z-index: 10;
    margin-top: -182px;
}



.item {

    position: relative;

    &__info {
        padding: 250px 0 100px 0;
    }

    &__img {
        position: relative;
        width: 100%;
        height: 100%;

        img {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    &__title {
        font-size: 48px;
        font-weight: 600;
        margin-bottom: 20px;
        line-height: 1.3;
    }

    &__subtitle {
        font-size: 24px;
        margin-bottom: 20px;
        line-height: 1.3;
    }

    &__date {
        padding: 7px 15px;
        background: #fff;
        border-radius: 4px;
        font-size: 24px;
        display: inline-block;
        margin-bottom: 40px;

    }

    &__btn {
        display: block;
        max-width: 267px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        background: $blue;
        padding: 23px 40px;
        border-radius: 4px;

        &:hover {
            color: #fff;
            background: darken($blue, 5%);
        }
    }
}

@media only screen and (max-width : 768px) {
    .item {
        &__info {
            padding: 210px 0 20px 0;
        }

        &__img {

            img {
                position: relative;

            }
        }

    }
}

@media only screen and (max-width : 576px) {
    .item {
        &__title {
            font-size: 24px;
        }

        &__subtitle {
            font-size: 18px;

        }

        &__date {
            font-size: 18px;

        }


    }
}
