.request {
    border: 1px solid #e5e5e5;
    background: #fafafa;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;

    &__wrp {
        flex-basis: 80%;
        display: flex;

        img {
            margin-right: 15px;
        }
    }

    &__text {
        color: $fontd;
        line-height: 1.4;
        font-size: 18px;
    }

    &__btn {
        flex-basis: 20%;
        background: $blue;
        color: #fff;
        font-weight: 600;
        padding: 15px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        transition: all .3s;

        &:hover {
            background: $bluel;
        }
    }
}

@media only screen and (max-width : 1200px) {
    .request {
        flex-wrap: wrap;


        &__wrp {
            flex-basis: 100%;
            margin-bottom: 20px;

        }

        &__btn {
            flex-basis: 30%;
        }
    }
}

@media only screen and (max-width : 712px) {
    .request {

        &__wrp {
            img {
                max-width: 40px;

            }

        }

        &__btn {
            flex-basis: 100%;
        }
    }

}
