.btn-white {
    background: #fff;
    color: $bluel;
    padding: 15px 25px;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    border-radius: 4px;
    margin-right: 5px;
    margin-bottom: 10px;
}

.btn-blue {
    background: $bluel;
    color: #fff;
    padding: 15px 25px;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    border-radius: 4px;
    cursor: pointer;


    &:hover {
        background: #5a7095;
    }
}
