.heading {
    background: #fff;
    padding: 30px 0px 30px 0;

    h1 {

        font-size: 32px;
        color: $fontd;
        line-height: 1.3;
        margin-bottom: 20px;
        margin-top: 0;
        font-weight: 600;
    }

}

.breadcrumbs {
    li {
        display: inline-block;
        font-size: 15px;
        color: $fontl;

        a {
            color: $fontl;
            text-decoration: none;


            &:hover {
                color: $fontl;

            }
        }
    }

    li + li {
        &::before {
            color: $fontl;
            content: "—";
            padding: 0 5px;
        }

    }
}
