.category-page {
    background: #fff;
    margin: 20px 0;
    position: relative;
    transition: .3s;
    display: block;
    width: 100%;


    &__img {
        width: 100%;
        display: block;
        height: 250px;
        position: relative;
        z-index: 60;
        margin-bottom: 20px;

        img {
            height: 100%;
            object-fit: cover;
        }
    }


    &__title {
        font-weight: 600;
        color: $fontd;
        font-size: 18px;
        line-height: 1.4;
        margin-bottom: 20px;
        position: relative;
        z-index: 60;
    }

    &__descr {
        position: absolute;
        opacity: 0;
        padding: 15px;
        line-height: 1.4;
        color: $fontl;
        box-shadow: 0 0 20px #00000012;
        bottom: 0%;
        left: 0;
        width: 100%;
        background: #fff;
        z-index: 90;
        max-height: 200px;
        transition: .3s;


    }

    &:hover .category-page__descr {
        opacity: 1;
    }
}

/*Скролл*/

.simplebar-track.simplebar-vertical {
    background-color: #d9d9d9;
    width: 5px;
}

.simplebar-content-wrapper {
    padding-right: 15px;
}

.simplebar-scrollbar::before {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    background-color: #2871c5;
    border-radius: 0;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
    top: 0;
    bottom: 0;
}

.simplebar-scrollbar.simplebar-visible::before {
    opacity: 1;
}


@media only screen and (max-width : 576px) {
    .order1 {
        order: 2;
    }

    .order2 {
        order: 1;
    }
}
