.tizers {
    padding: 40px 0;
}

.tizer {
    margin-bottom: 20px;

    &__img {
        margin-bottom: 20px;

        img {
            height: 70px;
        }
    }

    &__title {
        color: fontd;
        font-weight: 600;
        margin-bottom: 10px;
        line-height: 1.4;
        font-size: 18px;
    }

    &__txt {
        color: $fontl;
        line-height: 1.4;
        font-size: 18px;
    }
}

@media only screen and (max-width : 576px) {
    .tizer {
        display: flex;

        &__img {
            margin-right: 15px;
            flex-basis: 20%;

            img {
                height: 50px;
                width: 50px;
            }
        }

        &__info {
            flex-basis: 80%;
        }

        &__title {
            font-size: 16px;
        }

        &__txt {
            font-size: 16px;
        }
    }
}
