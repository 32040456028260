.product-card {

    background: #fff;

    hr {
        background-color: #ececec;
        opacity: 1;

    }
}


.infocard {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.infocard_sticker {
    background: $blue;
    color: #fff;
    padding: 7px 15px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.infocard_marker {
    font-size: 14px;
    display: flex;
    align-items: center;

    span {
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 50%;

    }

}

.infocard_marker__green {
    color: green;

    span {
        background: green;
    }


}


.ic-card {
    margin-right: 0;

}

.card-title {
    h1 {
        font-size: 24px;
        font-weight: 700;
        margin: 20px 0;
    }
}



.product_prices {
    display: flex;
    margin-bottom: 20px;

}

.product_prices-new {
    font-size: 23px;
    margin-right: 15px;
    font-weight: 600;
}

.product_prices-old {
    color: darkgray;
    text-decoration: line-through;
    margin-right: 15px;

}


.product_btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 60px;

}

.questions_block {
    position: relative;
    padding: 10px;
    border: 1px solid #ececec;
    margin-left: 20px;
    display: flex;
    align-items: center;


    a {

        &:hover {
            opacity: .7;
        }
    }
}

.questions_block--text {
    padding: 7px;
    background: #fff;
    font-size: 13px;
    color: darkgray;
    line-height: 1.4;


}

.q_link {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(90deg, rgba(255, 195, 40, 1) 18%, rgba(255, 141, 0, 1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;


}

.ic_quest {
    width: 17px;
    height: 17px;
    fill: #fff;
}




.tizers-card {
    display: flex;
    justify-content: space-between;

    .tizer {
        display: flex;
        flex-direction: column;
        padding: 25px 0;
        flex-basis: auto;

    }


}

.tizer_img {
    margin-bottom: 10px;
}

.tizer_img img {
    max-width: 40px;
    height: auto;
}

.tizer_txt {
    line-height: 1.3;
    font-size: 14px
}

.nav-card {
    margin-top: 30px;
}

.tables {
    display: flex;


    table {
        margin: 20px;

        .end {
            text-align: end;
        }
    }
}

article,
.description_card,
.card-data__row {

    padding: 20px 0;

    table {
        margin: 20px;
    }

    p {
        line-height: 1.5;
        margin-bottom: 7px;
    }

    ul {
        li {
            line-height: 1.5;
            margin: 7px;
        }

        span {
            width: 7px;
            height: 7px;
            display: inline-block;
            background: $blue;
            border-radius: 50%;
            margin-right: 10px;
            margin-bottom: 3px;
        }
    }


    .main_text {
        padding: 15px 15px 15px 40px;
        border-left: 3px solid $blue;
        background: #ececec;
        margin: 15px 0;
        display: block;
    }
}



.nav-tabs {
    border-bottom: 1px solid #ececec;
}


.ic_product {
    width: 50px;
    height: 50px;
    fill: $blue;
}


nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: #f7f7f7;
    border-color: #fff #fff #fff;
    border-bottom: 3px solid $blue;
    font-weight: 600;
    padding: 15px 20px;
    cursor: pointer;

}

nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
    font-size: 17px;
    cursor: pointer;
    padding: 15px 20px;
    color: gray;

    &:hover {
        border-color: #fff #fff $blue #fff;
    }
}

nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
    &:focus {
        border-bottom: 3px solid #244d72;
    }
}

.btn-product {
    background: $blue;
    color: #fff;
    font-size: 24px;
    padding: 15px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .3s;

    &:hover {
        background: darken($blue, 5%);
    }

}

.description_card ul {
    margin: 20px 0;
}

@media only screen and (max-width : 1200px) {
    .infocard {
        margin-top: 30px;
    }

    .product_prices {
        flex-direction: column;
    }

    .tables {
        flex-wrap: wrap;
    }

    .tizers-card {
        display: none;



    }


}

@media only screen and (max-width : 768px) {

    .tizers-card {
        flex-wrap: wrap;
    }

}

@media only screen and (max-width : 470px) {
    .questions_block {

        margin-top: 30px;
        margin-left: 0;
    }
}
