.content {
    background: #fff;
    box-shadow: 0 0 20px #ececec;
    padding: 30px;
    line-height: 1.4;

    .main-text {
        background: #ececec;
        padding: 30px 15px 30px 30px;
        border-left: 3px solid $blue;
        margin: 20px 0;
    }

    h2 {
        color: $fontd;
        font-size: 32px;
        margin: 10px 0;
        font-weight: 700;
    }

    h3 {
        color: $fontd;
        font-size: 28px;
        margin: 10px 0;
        font-weight: 600;
    }

    p {
        color: $fontl;
        padding: 7px 0;
    }

    .content-ul {
        list-style-type: disc;
        padding-left: 20px;

        color: $fontl;

        li {
            padding: 7px 0;

        }
    }
}

@media only screen and (max-width : 576px) {
    .content {
        padding: 0;
        box-shadow: none;

        h2 {

            font-size: 24px;

        }

        h3 {
            font-size: 22px;
        }
    }
}
