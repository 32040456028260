.categories {
    margin-top: 45px;
}

.category {
    height: 246px;
    margin: 0 5px;
    position: relative;
    display: block;
    opacity: .9;

    &:hover {
        opacity: 1;
    }

    &__img {
        width: 100%;
        position: relative;
        z-index: 10;


        img {
            height: 246px;
            object-fit: cover;
            width: 100%;
        }

    }

    &__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0.31%, rgba(0, 0, 0, 0) 74.39%);
        z-index: 20;
        bottom: 0;
        left: 0;

    }

    &__info {
        position: absolute;
        left: 20px;
        bottom: 15px;
        z-index: 30;
    }

    &__title {
        color: #fff;
        font-size: 18px;
        line-height: 1.3;
        margin-bottom: 10px;
        font-weight: 600;
    }

    &__description {
        line-height: 1.3;
        color: #fff;

    }
}
