.about {
    padding: 60px 0;
}

.about-text {
    margin-bottom: 15px;

    p {
        font-size: 18px;
        color: $fontl;
        line-height: 1.4;
        padding: 5px 0;
    }

    .p-bold {
        color: $fontd;
        font-weight: 600;
    }
}

.about_slider--img {
    width: 100%;


    img {
        display: block;
        height: 400px;
        object-fit: cover;

    }
}

@media only screen and (max-width : 576px) {
    .about {
        padding: 30px 0;

        p {
            font-size: 16px;
        }
    }

}
