.action_pic {
    margin-bottom: 15px;
}

.action-info {
    display: flex;
    align-items: center;

    &__yellow {
        display: inline-block;
        background: $yellow;
        color: $fontd;
        font-size: 14px;
        padding: 5px 10px;
        border-radius: 4px;
        margin-right: 15px;
    }

    &__white {
        border: 1px solid #ececec;
        color: $fontd;
        font-size: 14px;
        padding: 5px 10px;
        border-radius: 4px;
        display: inline-flex;

        align-items: center;

        span {
            margin-left: 10px;
        }

    }

    .ic_red {
        fill: red;
        width: 16px;
        height: 16px;
    }
}
