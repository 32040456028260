.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: $fontd;
    font-weight: 500;
    background-color: #fff;
    border-color: transparent transparent transparent;
    font-size: 18px;

}

.nav-tabs .nav-link.active {
    border-bottom: 2px solid $blue;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: transparent transparent transparent;
    isolation: isolate;
    border-bottom: 2px solid $blue;
}

.nav-tabs .nav-link {
    color: $fontl;
    font-weight: 500;
    font-size: 18px;

}

.nav-tabs {
    border-bottom: none;

}

/* bootstrap hack: fix content width inside hidden tabs */
.tab-content > .tab-pane,
.pill-content > .pill-pane {
    display: block;
    /* undo display:none          */
    height: 0;
    /* height:0 is also invisible */
    //overflow: hidden;  /* no-overflow                */
}

.tab-content > .active,
.pill-content > .active {
    height: auto;
    /* let the content decide it  */
}

/* bootstrap hack end */
@media only screen and (max-width : 576px) {

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        font-size: 16px;

    }

    .nav-tabs .nav-link {
        font-size: 16px;

    }



}
