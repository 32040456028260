.contacts {
    border: 1px solid #ececec;
    padding: 20px;

    &__top {
        width: 100%;
        height: 300px;
        position: relative;
        margin-bottom: 20px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__info {
        position: absolute;
        bottom: 0;
        left: 0px;
        background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
        padding-top: 30px;
        padding-left: 20px;
        padding-right: 10px;
        width: 100%;

    }

    &__subtitle {
        font-size: 14px;
        color: #fff;
        margin-bottom: 20px;

    }

    &__title {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        margin-bottom: 20px;
    }

    &__group {
        margin: 20px 0;
    }

    &__label {
        font-size: 16px;
        color: $fontl;
        margin-bottom: 10px;
    }

    &__input {

        margin-bottom: 10px;

        a {
            color: $fontd;
            font-size: 18px;
            text-decoration: none;
            display: block;
            margin-bottom: 10px;
            font-weight: 600;

        }
    }

    &__btn {
        width: 100%;
        background: #fff;
        border: 1px solid $blue;
        color: $blue;
        font-weight: 600;
        padding: 15px 5px;
        cursor: pointer;
        transition: all .3s;
        display: block;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;

        &:hover {
            border: 1px solid $blue;
            color: #fff;
            background: $blue;

        }
    }

}

.content-contacts {
    margin: 20px 0;
}
