.product-tabs {
    background: #F6F7FA;
    padding: 40px 0;

}

.pos-relative {
    position: relative;
}

.product-navs {
    padding-top: 80px;
}

.block-title {
    color: $fontd;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 40px;
}

@media only screen and (max-width : 576px) {
    .block-title {

        font-size: 22px;

    }

    .product-navs {
        padding-top: 40px;
    }

    .product-tabs {
        padding: 80px 0;

    }

}
